import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { languageCookieName } from "../variables"
import getEnv from '../Helpers/env';


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        fallbackLng: 'en',
        detection: {
            order: [
                'cookie',
                'htmlTag',
                'localStorage',
                'sessionStorage',
                'navigator',
                'path',
                'subdomain'
            ],
            caches: [
                'cookie'
            ],
            lookupCookie: languageCookieName,
            cookieOptions: {
                path: '/',
                domain: getEnv('appCookiesUrl'),
            },
        },
        // debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            // loadPath: '/locales/{{lng}}/translation.json',
            loadPath: '/locales/{{lng}}/translation.json?n=14',
        }
    });

export default i18n;