import { Link } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import AvatarDropDown from "./AvatarDropDown";
import NavLogo from "../../../NavBar/NavLogo";
import ChangeLang from "../../../Language/ChangeLang";
import { useTranslation } from "react-i18next";


export default function Header() {

    const { t } = useTranslation()

    const { authUser, isAuth, logout, isLoading } = useAuth();

    const { role } = authUser?.data ?? {};

    // handle logout click
    const handleLogout = async () => {
        await logout()
    }

    return (
        <header className="bg-white w-full border-b">
            <div className="max-w-6xl mx-auto h-[65px] w-full px-4 flex items-center justify-between">
                <NavLogo />

                {!isAuth && (
                    <div className="flex items-center gap-4">
                        <ChangeLang />
                        <Link to="/?auth=login" className="hover:underline textBody-s2 max-sm:hidden">{t("header_btn_1")}</Link>
                        <Link to="/?auth=register" className="px-6 py-2.5 hover:bg-primary2 textBody-s2 bg-primary text-white rounded-full transition-colors duration-200">{t("header_btn_2")}</Link>
                    </div>
                )}


                {isAuth && (
                    <div className="flex items-center gap-2">
                        
                        <ChangeLang />

                        <AvatarDropDown
                            authUser={authUser}
                            logout={handleLogout}
                            inProgress={isLoading}
                            role={role}
                        />
                    </div>
                )}
            </div>
        </header>
    )
}