import { useState } from "react";
import SalonInformations from "../../components/Pages/Salon/SalonInformations";
import BookNowButton from "../../components/Pages/Salon/BookNowButton";
import ChooseDate from "../../components/Pages/Salon/ChooseDate";
import ChooseTime from "../../components/Pages/Salon/ChooseTime";
import { useNavigate, useParams } from 'react-router-dom';
import { GetSalonDataBySlug, NewAppointment, GetAvailableTimeBySalon } from "../../lib/api";
import { isNull } from "../../Helpers/utils";
import { useAuth } from "../../hooks/useAuth";
import { useAlerts } from "../../context/AlertsContext";
import dayjs from "../../lib/dayjs";
import ReservationSuccessMesasage from "../../components/Pages/Salon/ReservationSuccessMesasage";
import useHtmlAttributes from "../../hooks/useHtmlAttributes";
import { useTranslation } from "react-i18next";
import Authentication from "../../components/Authentication";


export default function Salon() {

    const { mpt } = useHtmlAttributes();

    const { t } = useTranslation()

    const { slug } = useParams();

    const { addAlert } = useAlerts();

    const navigate = useNavigate();

    const { isAuth, authUser } = useAuth();

    const { data, loading } = GetSalonDataBySlug({ slug });
    const { data: salonData } = data ?? {};
    

    // for reservation form
    const canReserve = !loading && !isNull(salonData);


    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState('');


    // get available time
    const params = !canReserve ? {} : {
        date: dayjs(selectedDate).format('YYYY-MM-DD'),
        salon: slug
    };

    const { data: availableTimeData, loading: availableTimeLoading } = GetAvailableTimeBySalon(params);
    const timesSlots = availableTimeData?.data ?? [];

    // for "Book now" button
    const canBook = !isNull(selectedDate) && !isNull(selectedTime);


    // send reservation request
    const [isLoading, setIsLoading] = useState(false)
    const [isReserved, setIsReserved] = useState(false)
    const saveNewAppointment = async () => {

        setIsLoading(true)

        const data = {
            date: dayjs(selectedDate).format('YYYY-MM-DD'),
            time: selectedTime,
            salon: salonData.id,
        };

        const { is_ok, message, type } = await NewAppointment(data);

        setIsLoading(false)

        if (type === "validation") {
            // 
        } else {
            addAlert(message ?? (is_ok ? t('booking_8') : t('booking_9')), is_ok ? 'success' : 'error');
        }

        if (is_ok) {
            // navigate('/')
            setIsReserved(true)
        }
    }

    const handleReserve = async () => {
        if (!isAuth) {
            navigate(`/${slug}?auth=register&t=clients`)
        } else {
            if (!authUser?.isLoading && !isNull(authUser?.data)) {
                if (authUser?.data?.role === 'client') {
                    // send request
                    saveNewAppointment()
                } else {
                    addAlert(t('booking_7'), 'error')
                }
            }
        }
    }


    return (
        <main>
            {mpt()}
            <Authentication />
            <div className="">
                <div className='min-h-dvh'>
                    <SalonInformations
                        loading={loading}
                        salonData={salonData}
                    />

                    {canReserve && !isReserved &&
                        <div className="w-full max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 mb-20 py-10">
                            <ChooseDate
                                selectedDate={selectedDate}
                                setSelectedDate={(d) => {
                                    setSelectedDate(d)
                                    setSelectedTime('')
                                }}
                                t={t}
                            />
                            <ChooseTime
                                isLoading={availableTimeLoading}
                                availableTimes={timesSlots}
                                selectedTime={selectedTime}
                                setSelectedTime={setSelectedTime}
                                t={t}
                            />
                        </div>}


                    {isReserved &&
                        <ReservationSuccessMesasage
                            t={t}
                        />
                    }
                </div>

                {canReserve && !isReserved &&
                    <BookNowButton
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        canBook={canBook}
                        isLoading={isLoading}
                        onClick={handleReserve}
                        t={t}
                    />}


            </div>
        </main>
    )
}